<template>
  <div class="container-fluid text-center">
	<div class="upvote vote-button" :class="{ upvoted: isUpvoted }" @click="toggleUpvote()">
		<svg width="36" height="36" style="transform: rotate(-180deg)">
			<path d="M2 10h32L18 26 2 10z" fill="currentColor"></path>
		</svg>
	</div>
	<span class="vote-count">{{ upvoteCount }}</span>
  </div>
</template>

<script>
export default {
	data() {
		return {
			upvoteCount: this.upvotes,
			isUpvoted: this.upvoted
		};
	},
	methods: {
		toggleUpvote() {
			if(this.isUpvoted) {
				this.$emit("removeUpvote");
				this.isUpvoted = false;
				this.upvoteCount--;
			} else {
				this.$emit("addUpvote");
				this.isUpvoted = true;
				this.upvoteCount++;
			}
		}
	},
	props: {
		upvoted: {
			type: Boolean,
			default: false
		},
		upvotes: {
			type: Number,
			required: true
		}
	}
};
</script>

<style lang="scss" scoped>
.vote-button {
	color: #8d8c8c;
	transition: .3s;
}

.upvote {
	&:hover {
		cursor: pointer;
	}

	&:hover:not(.upvoted), &.upvoted {
		color: green;
		transform: scale(1.1);
	}

	&.upvoted:hover {
		color: #8d8c8c;
		transform: scale(1);
	}
}

.vote-count {
	font-weight: 800;
}
</style>
